@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-Light.eot');
	src: local('Montserrat Light'), local('Montserrat-Light'),
		url('./fonts/Montserrat-Light.eot') format('embedded-opentype'),
		url('./fonts/Montserrat-Light.woff') format('woff'),
		url('./fonts/Montserrat-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-MediumItalic.eot');
	src: local('Montserrat Medium Italic'), local('Montserrat-MediumItalic'),
		url('./fonts/Montserrat-MediumItalic.eot') format('embedded-opentype'),
		url('./fonts/Montserrat-MediumItalic.woff') format('woff'),
		url('./fonts/Montserrat-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-Thin.eot');
	src: local('Montserrat Thin'), local('Montserrat-Thin'),
		url('./fonts/Montserrat-Thin.eot') format('embedded-opentype'),
		url('./fonts/Montserrat-Thin.woff') format('woff'),
		url('./fonts/Montserrat-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-ExtraLightItalic.eot');
	src: local('Montserrat ExtraLight Italic'), local('Montserrat-ExtraLightItalic'),
		url('./fonts/Montserrat-ExtraLightItalic.eot') format('embedded-opentype'),
		url('./fonts/Montserrat-ExtraLightItalic.woff') format('woff'),
		url('./fonts/Montserrat-ExtraLightItalic.ttf') format('truetype');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-BoldItalic.eot');
	src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'),
		url('./fonts/Montserrat-BoldItalic.eot') format('embedded-opentype'),
		url('./fonts/Montserrat-BoldItalic.woff') format('woff'),
		url('./fonts/Montserrat-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-SemiBold.eot');
	src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
		url('./fonts/Montserrat-SemiBold.eot') format('embedded-opentype'),
		url('./fonts/Montserrat-SemiBold.woff') format('woff'),
		url('./fonts/Montserrat-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-ExtraLight.eot');
	src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'),
		url('./fonts/Montserrat-ExtraLight.eot') format('embedded-opentype'),
		url('./fonts/Montserrat-ExtraLight.woff') format('woff'),
		url('./fonts/Montserrat-ExtraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-ExtraBoldItalic.eot');
	src: local('Montserrat ExtraBold Italic'), local('Montserrat-ExtraBoldItalic'),
		url('./fonts/Montserrat-ExtraBoldItalic.eot') format('embedded-opentype'),
		url('./fonts/Montserrat-ExtraBoldItalic.woff') format('woff'),
		url('./fonts/Montserrat-ExtraBoldItalic.ttf') format('truetype');
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-Italic.eot');
	src: local('Montserrat Italic'), local('Montserrat-Italic'),
		url('./fonts/Montserrat-Italic.eot') format('embedded-opentype'),
		url('./fonts/Montserrat-Italic.woff') format('woff'),
		url('./fonts/Montserrat-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-Bold.eot');
	src: local('Montserrat Bold'), local('Montserrat-Bold'),
		url('./fonts/Montserrat-Bold.eot') format('embedded-opentype'),
		url('./fonts/Montserrat-Bold.woff') format('woff'),
		url('./fonts/Montserrat-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-LightItalic.eot');
	src: local('Montserrat Light Italic'), local('Montserrat-LightItalic'),
		url('./fonts/Montserrat-LightItalic.eot') format('embedded-opentype'),
		url('./fonts/Montserrat-LightItalic.woff') format('woff'),
		url('./fonts/Montserrat-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-BlackItalic.eot');
	src: local('Montserrat Black Italic'), local('Montserrat-BlackItalic'),
		url('./fonts/Montserrat-BlackItalic.eot') format('embedded-opentype'),
		url('./fonts/Montserrat-BlackItalic.woff') format('woff'),
		url('./fonts/Montserrat-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-SemiBoldItalic.eot');
	src: local('Montserrat SemiBold Italic'), local('Montserrat-SemiBoldItalic'),
		url('./fonts/Montserrat-SemiBoldItalic.eot') format('embedded-opentype'),
		url('./fonts/Montserrat-SemiBoldItalic.woff') format('woff'),
		url('./fonts/Montserrat-SemiBoldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-Regular.eot');
	src: local('Montserrat Regular'), local('Montserrat-Regular'),
		url('./fonts/Montserrat-Regular.eot') format('embedded-opentype'),
		url('./fonts/Montserrat-Regular.woff') format('woff'),
		url('./fonts/Montserrat-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-Medium.eot');
	src: local('Montserrat Medium'), local('Montserrat-Medium'),
		url('./fonts/Montserrat-Medium.eot') format('embedded-opentype'),
		url('./fonts/Montserrat-Medium.woff') format('woff'),
		url('./fonts/Montserrat-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-ExtraBold.eot');
	src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
		url('./fonts/Montserrat-ExtraBold.eot') format('embedded-opentype'),
		url('./fonts/Montserrat-ExtraBold.woff') format('woff'),
		url('./fonts/Montserrat-ExtraBold.ttf') format('truetype');
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-Black.eot');
	src: local('Montserrat Black'), local('Montserrat-Black'),
		url('./fonts/Montserrat-Black.eot') format('embedded-opentype'),
		url('./fonts/Montserrat-Black.woff') format('woff'),
		url('./fonts/Montserrat-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Montserrat';
	src: url('./fonts/Montserrat-ThinItalic.eot');
	src: local('Montserrat Thin Italic'), local('Montserrat-ThinItalic'),
		url('./fonts/Montserrat-ThinItalic.eot') format('embedded-opentype'),
		url('./fonts/Montserrat-ThinItalic.woff') format('woff'),
		url('./fonts/Montserrat-ThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}

* {
  font-family: 'Montserrat'!important;
}

.App {
  text-align: center;
  /* background-color: rgba(0,176,255,1); */
  background-image: url("background.jpg");
      background-size: cover;
  background-repeat: no-repeat;
    background-attachment: fixed; 
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiInputBase-input{
  border-radius: 14px;
  background-color: rgba(245, 245, 245, 0.8);
}

.jss1, .jss2 {
  background: transparent!important;
}

.MuiContainer-root{
  background: rgb(246, 246, 246);
}
.jss6 {
  max-width: 712px!important;
  border-radius: 14px;
}
.jss6 form{
  margin-top: 40px;
}
.MuiFilledInput-underline:before {
  display: none;
}
.MuiInputBase-root{

  border-radius: 14px!important;
}

.MuiFormControl-root.jss9{
  flex-direction: row;
    width: calc(100% - 55px);
    background-color: rgba(0, 0, 0, 0.09);
    border-radius: 14px;
    padding: 10px 27px;
    align-items: center;
}
.MuiFormControl-root.jss9 .MuiFormControl-root, .MuiSelect-root, .MuiFilledInput-root, .MuiAutocomplete-root{
  width: 100%;
}

.MuiFormControl-root.jss9 .MuiInputBase-root{
  background: transparent!important;
}
.MuiFormLabel-root{
  top: -17px!important;
}
.MuiGrid-spacing-xs-4 > .MuiGrid-item {
  padding: 16px!important;
}
@media (max-width: 700px){
  .MuiFormHelperText-root{
    font-size: 0.5rem!important;
  }
}
.jss8{
  letter-spacing: 0.5px;
    line-height: 150%;
    font-size: 18px;
}
.platforms-select-option:last-of-type{
  font-style: italic;
}

.white-space{
    white-space: break-spaces!important;
}
.makeStyles-titleCaptionsContainer-2 {
  background-color: white;
}

.makeStyles-inputLabel-13 {
  white-space: break-spaces !important;
  min-width: 200px !important;
}